var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('table-view',{ref:"table",attrs:{"api":"offer","filters":_vm.filters,"headers":_vm.headers,"popup":_vm.popup,"save":_vm.save,"edit":_vm.edit,"ignore-status":""},on:{"click:item":function($event){return _vm.edit( $event.item.item )}},scopedSlots:_vm._u([{key:"item.value.picture",fn:function(ref){
var value = ref.value;
return [_c('d-image',{attrs:{"value":value,"width":_vm.imageDimension,"height":_vm.imageDimension,"color":value ? 'transparent' : undefined,"item-image":"url","hide-placeholder":"","outlined":""}})]}},{key:"item.value.customer",fn:function(ref){
var value = ref.value;
return [(value)?[_c('p',{staticClass:"mb-1"},[_c('router-link',{attrs:{"to":("/customer?id=" + (value.id))}},[_vm._v(" "+_vm._s(value.name)+" ")])],1),(value.mail)?_c('p',{staticClass:"mb-1"},[_vm._v(" "+_vm._s(value.mail)+" ")]):_vm._e(),(value.phone)?_c('p',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(value.phone)+" ")]):_vm._e()]:_vm._e()]}},{key:"item.value.conditions",fn:function(ref){
var value = ref.value;
return [_c('div',{staticClass:"three-line-text"},[_vm._v(" "+_vm._s(value)+" ")])]}},{key:"item.value.timetable",fn:function(ref){
var item = ref.item;
return [(item.offerTimetable)?_c('div',[_c('div',{staticClass:"one-line-text"},[_c('span',{staticClass:"caption"},[_vm._v(" "+_vm._s(_vm.$t('inputs.rangeDate.from.display'))+" ")]),_vm._v(" "),_c('span',{staticClass:"font-weight-medium"},[_vm._v(" "+_vm._s(_vm.displayDate( item.offerTimetable.startDate ))+" ")])]),(item.offerTimetable.endDate)?_c('div',{staticClass:"one-line-text"},[_c('span',{staticClass:"caption"},[_vm._v(" "+_vm._s(_vm.$t('inputs.rangeDate.to.display'))+" ")]),_vm._v(" "),_c('span',{staticClass:"font-weight-medium"},[_vm._v(" "+_vm._s(_vm.displayDate( item.offerTimetable.endDate ) || '-')+" ")])]):_vm._e(),_c('div',{staticClass:"one-line-text"},[_c('span',{staticClass:"caption"},[_vm._v(" "+_vm._s(_vm.$t('inputs.rangeDate.from.time'))+" ")]),_vm._v(" "),_c('span',{staticClass:"font-weight-medium"},[_vm._v(" "+_vm._s(_vm.displayOffset( item.offerTimetable.startOffset ))+" ")]),_vm._v(" "),_c('span',{staticClass:"caption"},[_vm._v(" "+_vm._s(_vm.$t('inputs.rangeDate.to.time'))+" ")]),_vm._v(" "),_c('span',{staticClass:"font-weight-medium"},[_vm._v(" "+_vm._s(_vm.displayOffset( item.offerTimetable.endOffset ))+" ")])]),_c('weekdays',{attrs:{"value":item.offerTimetable,"small":"","display":""}})],1):_vm._e()]}},{key:"item.value.matchingsCount",fn:function(ref){
var value = ref.value;
var item = ref.item;
return [_c('btn',{attrs:{"color":"primary","icon-left":"mdi-eye-outline","to":("/booking?offer=" + (item.id)),"small":""}},[_vm._v(" "+_vm._s(_vm.$t('offerMatch.title'))+" ("+_vm._s(value)+") ")])]}},{key:"item.value.status",fn:function(ref){
var value = ref.value;
return [_c('status',{attrs:{"value":value,"block-value":_vm.blockValue}})]}},{key:"form",fn:function(){return [_c('offer-form',{ref:"form",attrs:{"status-items":_vm.offerStatus,"is-new":_vm.isNew,"save":_vm.save},model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}})]},proxy:true},{key:"footer",fn:function(){return [_c('v-spacer'),_c('btn',{staticClass:"mr-2",attrs:{"color":"primary","loading":_vm.exporting},on:{"click":function($event){$event.stopPropagation();return _vm.exportData.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.$t('btn.export'))+" ")]),_c('btn',{on:{"click":function($event){$event.stopPropagation();return _vm.edit()}}},[_vm._v(" "+_vm._s(_vm.$t('offer.btn.new'))+" ")])]},proxy:true}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('m-dialog',{attrs:{"title":_vm.$t('offer.changeStatusTitle'),"max-width":"480","persistent":"","actions":""},on:{"click:accept":_vm.onDisableOffer},model:{value:(_vm.alert),callback:function ($$v) {_vm.alert=$$v},expression:"alert"}},[_vm._v(" "+_vm._s(_vm.$t('offer.changeStatusText'))+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }