<template>
  <table-view
    ref="table"
    api="offer"
    v-model="dialog"
    :filters="filters"
    :headers="headers"
    :popup="popup"
    :save="save"
    :edit="edit"
    @click:item="edit( $event.item.item )"
    ignore-status
  >
  <template v-slot:item.value.picture="{ value }">

    <d-image
      :value="value"
      :width="imageDimension"
      :height="imageDimension"
      :color="value ? 'transparent' : undefined"
      item-image="url"
      hide-placeholder
      outlined
    />

  </template>
  <template v-slot:item.value.customer="{ value }">
    <template v-if="value">

      <p class="mb-1">
        <router-link :to="`/customer?id=${value.id}`">
          {{ value.name }}
        </router-link>
      </p>

      <p class="mb-1" v-if="value.mail">
        {{ value.mail }}
      </p>

      <p class="mb-0" v-if="value.phone">
        {{ value.phone }}
      </p>

    </template>
  </template>
  <template v-slot:item.value.conditions="{ value }">

    <div class="three-line-text">
      {{ value }}
    </div>

  </template>
  <template v-slot:item.value.timetable="{ item }">

    <div v-if="item.offerTimetable">
      <div class="one-line-text">
        <span class="caption">
          {{ $t('inputs.rangeDate.from.display') }}
        </span> <span class="font-weight-medium">
          {{ displayDate( item.offerTimetable.startDate ) }}
        </span>
      </div>
      <div v-if="item.offerTimetable.endDate" class="one-line-text">
        <span class="caption">
          {{ $t('inputs.rangeDate.to.display') }}
        </span> <span class="font-weight-medium">
          {{ displayDate( item.offerTimetable.endDate ) || '-' }}
        </span>
      </div>
      <div class="one-line-text">
        <span class="caption">
          {{ $t('inputs.rangeDate.from.time') }}
        </span> <span class="font-weight-medium">
          {{ displayOffset( item.offerTimetable.startOffset ) }}
        </span> <span class="caption">
          {{ $t('inputs.rangeDate.to.time') }}
        </span> <span class="font-weight-medium">
          {{ displayOffset( item.offerTimetable.endOffset ) }}
        </span>
      </div>

      <weekdays
        :value="item.offerTimetable"
        small display
      />

    </div>

  </template>
  <template v-slot:item.value.matchingsCount="{ value, item }">

    <btn
      color="primary"
      icon-left="mdi-eye-outline"
      :to="`/booking?offer=${item.id}`"
      small
    >
      {{ $t('offerMatch.title') }} ({{ value }})
    </btn>

  </template>
  <template v-slot:item.value.status="{ value }">

    <status
      :value="value"
      :block-value="blockValue"
    />

  </template>
  <template v-slot:form>

    <offer-form
      ref="form"
      v-model="model"
      :status-items="offerStatus"
      :is-new="isNew"
      :save="save"
    />

  </template>
  <template v-slot:footer>

    <v-spacer/>

    <btn
      class="mr-2"
      color="primary"
      :loading="exporting"
      @click.stop="exportData"
    >
      {{ $t('btn.export') }}
    </btn>

    <btn @click.stop="edit()">
      {{ $t('offer.btn.new') }}
    </btn>

  </template>

  <m-dialog
    v-model="alert"
    :title="$t('offer.changeStatusTitle')"
    max-width="480"
    @click:accept="onDisableOffer"
    persistent
    actions
  >
    {{ $t('offer.changeStatusText') }}
  </m-dialog>

  </table-view>
</template>

<script>
import TableView from '@/components/TableView';
import DImage from '@/components/Image';
import Status from '@/components/values/Status';
import Weekdays from '@/components/values/Weekdays';
import OfferForm from './Form';
import { numberToTime, timeToDate } from '@/components/form/TimeField';
import { ICONS, OFFER_STATUS } from '@/utils/constants';
import { displayDate, displayTime } from '@/utils';
import { mapGetters } from 'vuex';

export default {
  name: 'Offers',
  auth: true,
  head: vm => ({ title: vm.$i18n.t('offer.title') }),
  layout: 'admin',
  layoutProps: ({ i18n }) => ({
    title: i18n.t('offer.title'),
    icon: ICONS.offer
  }),
  components: { TableView, DImage, Status, Weekdays, OfferForm },
  data: () => ({
    model: {},
    dialog: false,
    exporting: false,
    alert: false,
    forceSave: false,
    isNew: false,
    blockValue: OFFER_STATUS.BLOCKED,
    filters: [],
    popup: { maxWidth: 860 }
  }),
  computed: {
    ...mapGetters( 'app', [ 'imageDimension' ]),
    offerStatus() {
      return Object.values( OFFER_STATUS ).map( v => ({
        value: Number(v),
        text: this.$t('offer.status.' + v )
      }))
    },
    headers() {
      return [
        {
          key: 'picture',
          text: this.$t('inputs.picture.label'),
          width: this.imageDimension,
          sorteable: false,
          filter: false
        },
        {
          key: 'customer',
          text: this.$t('inputs.customer.label'),
          mobile: true,
          filter: {
            is: 'api-field',
            api: 'customer',
            key: 'customerId',
            itemSearch: 'name',
            clearable: true,
            //multiple: true
          }
        },
        {
          key: 'conditions',
          text: this.$t('inputs.conditions.label'),
        },
        {
          key: 'timetable',
          text: this.$t('inputs.timetable.label'),
          sorteable: false,
          filter: false
        },
        {
          key: 'people',
          text: this.$t('inputs.people.label'),
          align: 'center',
          filter: {
            is: 'number-field',
            min: 0
          }
        },
        {
          key: 'priority',
          text: this.$t('inputs.priority.label'),
          align: 'center',
          filter: { is: 'number-field' }
        },
        {
          key: 'matchingsCount',
          text: this.$t('offerMatch.title'),
          width: 1,
          filter: {
            is: 'number-field',
            min: 0
          }
        },
        {
          key: 'status',
          text: this.$t('inputs.status.label'),
          mobile: true,
          width: 1,
          align: 'center',
          filter: {
            is: 'select-field',
            clearable: true,
            items: this.offerStatus
          }
        }
      ];
    },
  },
  methods: {
    displayDate,
    displayOffset( value ) {
      if ( ! value ) return '';
      value = timeToDate( numberToTime( value * 60000 ));
      return displayTime( value );
    },
    edit( item ) {

      this.dialog = true;
      this.isNew = !item;
      this.popup = {
        ...this.popup,
        title: this.$t(`offer.btn.${ item ? 'edit': 'new' }`)
      };

      if ( ! this.isNew ) {

        item = { // Clone
          ...item,
          currentStatus: item.status,
          offerTimetable: item.offerTimetable
            ? { ...item.offerTimetable }
            : null
        };

        [ 'startOffset', 'endOffset' ].forEach( key => {
          if ( ! item.offerTimetable || ! item.offerTimetable[key] ) return;
          item.offerTimetable[key] = item.offerTimetable[key] * 60000; // Minutes to milisecons
        });

        this.$router.replace({
          path: this.$route.path,
          params: this.$route.params,
          query: { ...this.$route.query, id: item.id }
        }).catch(() => {});
      }

      this.$nextTick(() => {
        this.$refs.form.resetValidation();
        this.model = {
          id: -1,
          status: 1,
          ...item
        };
      });
    },
    sanitizeTime( data ) {

      data = { ...data };
      data.endDate = data.endDate || 0;

      [ 'startOffset', 'endOffset' ].forEach( key => {
        if ( ! data[key] ) return;
        data[key] = new Date( data[key] );
        data[key] = data[key].getHours() * 60 + data[key].getMinutes();
      });

      return data;
    },
    sanitize( model ) {
      return {
        ...model,
        people: model.people || 0,
        currentStatus: undefined,
        matchings: undefined,
        matchingsCount: undefined,
        offerTimetable: undefined,
        picture: undefined,
        customer: undefined,
        customerId: model.customer
          ? model.customer.id || model.customer
          : null,
        pictureId: model.picture
          ? model.picture.id
          : null
      };
    },
    exportData() {
      if ( this.exporting ) return;
      this.exporting = true;
      this.$refs.table.exportData().finally(() => {
        this.exporting = false;
      });
    },
    onDisableOffer() {
      this.alert = false;
      this.forceSave = true;
      this.save();
    },
    save() {
      if ( this.$refs.form.validate()) {

        this.dialog = false;
        this.model = this.$refs.form.model;

        if (
          !this.forceSave &&
          this.model.currentStatus === OFFER_STATUS.ENABLED &&
          this.model.status === OFFER_STATUS.DISABLED
        ) {
          return this.alert = true;
        }

        this.$store
          .dispatch( 'api/offer/setTimetable', this.sanitizeTime( this.model.offerTimetable ))
          .then( res => this.$store.dispatch( 'api/offer/set', {
            ...this.sanitize( this.model ),
            offerTimetableId: res.id
          }))
          .then(() => this.$refs.table.refresh())
          .then(() => {
            this.$store.dispatch( 'app/success', {
              message: this.$t('alerts.save')
            });
          }).catch( err => {
            this.$store.dispatch( 'app/error', {
              message: err.message
            });
          }).finally(() => {
            this.forceSave = false;
          });
      }
    }
  }
}
</script>
